import { defineStore } from "pinia"
import { ref, computed } from "vue"
import { useToast } from "vuestic-ui"
import { useRouter } from "vue-router"
import { useI18n } from "vue-i18n"
import { updateAgency,getMetrics, sendRegistrationRequest, updatePassword,resetPassword, fetchAgencyDataFromTbc, recoverPassword, fetchPaises } from "../api/auth"
import {
  welcome,
  checkToken,
  login as getAuth,
  exit
} from "../api/auth"

export const useAuthStore = defineStore("auth", () => {
  const { push } = useRouter()
  const { t } = useI18n()
  const toast = useToast()
  const authUser = ref(null)
  const language = ref("es")
  const public_token = computed(() => localStorage.getItem("public_token"))
  const private_token = computed(() => localStorage.getItem("private_token"))
  const lastVisited = ref("")
  const loading = ref(false)
  const errorMessage = ref("")

  async function checkAccess() {
    welcome()
      .then((response) => {
        localStorage.setItem("public_token", response.data.public_token)
        if (private_token.value) {
          checkPrivateToken()
        } else {
          push("/login")
          loading.value = false;
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  function checkPrivateToken() {
    checkToken(private_token.value)
      .then((response) => {
        if (response.data.user == 'undefined' || response.data.user == null) {
          console.warn("Token inválido")
          localStorage.removeItem("private_token");
          localStorage.removeItem("authUser");
          toast.init({
            message: "Por favor, inicie sesión nuevamente",
            duration: 3000,
            position: "top-right",
            color: "#ffffff"
          });
          push("/login");
          loading.value = false;
        } else {
          console.info("Token válido")
          push(lastVisited.value !== "" ? lastVisited.value : "/home");
          loading.value = false;
        }
      }).catch((error) => {
        localStorage.removeItem("private_token");
        localStorage.removeItem("authUser");
        push("/login");
        loading.value = false;
        console.log(error);
      });
  }

  function login(user) {
    loading.value = true
    getAuth(user)
      .then((response) => {
        loading.value = false
        if (response.data.user) {
          authUser.value = response.data.user;
          localStorage.setItem("private_token", response.data.access_token)
          localStorage.setItem("authUser", JSON.stringify(response.data.user))
          document.cookie = `private_token=${response.data.access_token}; max-age=10800; path=/auth/login`;

          push(lastVisited.value != "" ? lastVisited.value : "home")
          loading.value = false;
        } else {
          toast.init({
            message: "Credenciales incorrectas",
            duration: 3000,
            position: "top-right",
            color: "error"
          })
          loading.value = false;
        }
      })
      .catch((error) => {
        loading.value = false
        errorMessage.value = t("auth.login_error")
        push({ name: "login" })
        console.log(error)
      })
  }

  function logout() {
    exit(localStorage.getItem("private_token"))
      .then((response) => {
        localStorage.removeItem("private_token")
        localStorage.removeItem("authUser")
        authUser.value = null
        push({ name: "login" })
        loading.value = false;
      })
      .catch((error) => {
        localStorage.removeItem("private_token")
        localStorage.removeItem("authUser")
        authUser.value = null
        push({ name: "login" })
        loading.value = false;
        console.log(error)
      })
  }

  function initAuth() {
    const storedUser = localStorage.getItem("authUser")
    if (storedUser != 'undefined' && storedUser !== null) {
      authUser.value = JSON.parse(storedUser)
    }
    if (private_token.value != 'undefined' && private_token.value !== null) {
      checkPrivateToken()
    } else {
      push({ name: "login" })
      loading.value = false;
    }
  }

  async function changeUserData(user) {
    loading.value = false
    const response = updateAgency(user)
    return response;
  }

  async function changePassword(oldPass, pass) {
    const user = authUser.value ? authUser.value : null;
    const data = {
      password: pass,
      old_password: oldPass
    }

    const response = await updatePassword(user, data)

    return response;
  }

  async function register(agency) {
    return sendRegistrationRequest(agency)
      .then((response) => {
        loading.value = false
      }).catch((error) => {
        console.log(error)
        loading.value = false
      })
  }

  const getUserData = async () => {
    const response = fetchAgencyDataFromTbc(authUser.value.agencia_id)
    return response;
  }

  const recoverPasswordRequest = async (email) => {
    const response = await recoverPassword(email)
    return response;
  }

  const getPaises = async() => {
    const response = await fetchPaises()
    return response;
  }

  const getAgencyMetrics = async() => {
    const response = await getMetrics(private_token.value)
    return response;
  }

  const handleResetPassword = async(email, password, password_confirmation, token) => {
    const response = await resetPassword(email, password, password_confirmation, token)
    return response;
  }

  return {
    authUser,
    language,
    public_token,
    private_token,
    lastVisited,
    loading,
    errorMessage,
    checkAccess,
    login,
    logout,
    initAuth,
    changeUserData,
    changePassword,
    register,
    getUserData,
    recoverPasswordRequest,
    getPaises,
    getAgencyMetrics,
    handleResetPassword
  }
})
